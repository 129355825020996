import styled from '@emotion/styled';
import media from '@shared/mediaQuery';
import { getStaticImageUrl } from '@utils/createImageUrl';

const MainAdsStyle = styled('div')`
  .mainadscomp {
    &__header {
      position: relative;
    }
    &--title {
      display: flex;
      &-more {
        transform: rotate(-90deg);
        margin-left: 13px;
        ${media.mobile} {
          margin-top: 3px;
        }
        ${media.desktop} {
          margin-left: 13px;
          margin-top: 5px;
        }
      }
    }
    &--description {
      color: #555555;
      font-size: 13px;
      line-height: 19.5px;
      margin-top: 3px;
      letter-spacing: -0.1px;
      ${media.desktop} {
        font-size: 15px;
        margin-left: 8px;
        margin-top: 16px;
      }
    }

    &--banner {
      width: 100%;
      height: calc(100vw * 0.2);
      background-image: url(${getStaticImageUrl({ fileName: 'mainads-banner.jpg' })});
      background-size: cover;
      margin-top: 16px;
      ${media.desktop} {
        width: 944px;
        height: 212px;
        margin-top: 24px;
      }
    }
    &--contents {
      ${media.desktop} {
        display: flex;
      }
    }
    &--content {
      margin-top: 10px;
      ${media.desktop} {
        width: 464px;
        flex: none;
        margin-top: 23px;
        margin-right: 16px;
      }
      &-header {
        display: flex;
      }
      &-title {
        font-family: 'Pretendard-Bold';
        font-size: 14px;
        color: #3a3a3a;
        ${media.desktop} {
          font-size: 20px;
        }
      }
      &-description {
        font-size: 13px;
        color: #767676;
        line-height: 20px;
        margin-top: 1px;
        ${media.desktop} {
          font-size: 15px;
          color: #606060;
          word-break: keep-all;
          line-height: 24px;
          margin-top: 8px;
        }
      }
    }
  }
`;

export default MainAdsStyle;
