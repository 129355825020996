import styled from '@emotion/styled';
import { getStaticImageUrl } from '@utils/createImageUrl';
import media from '@shared/mediaQuery';

const MainFeaturesStyle = styled('div')`
  .mainfeatures {
    &--text-wrap {
      ${media.desktop} {
        max-width: 347px;
        flex: none;
        margin-top: 186px;
        margin-left: 66px;
      }
    }
    &--contents {
      position: relative;
      ${media.mobile} {
        margin-top: 12px;
        margin-left: -20px;
        width: calc(100% + 40px);
      }
      ${media.desktop} {
        width: 944px;
        margin-top: 16px;
      }
      &-dots {
        position: absolute;
        right: 16px;
        top: 239px;
        z-index: 1;
        ${media.desktop} {
          right: 29px;
          top: 275px;
        }
      }
      &-dot {
        background-color: #cfcfcf;
        border-radius: 100%;
        width: 8px;
        height: 8px;
        margin-bottom: 6px;
        &.active {
          background-color: #333333;
        }
      }
    }
    &--content {
      background-color: #f6f6f6;
      height: 530px;
      position: relative;
      ${media.mobile} {
        text-align: center;
      }
      ${media.desktop} {
        height: 600px;
      }
      &-body {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: opacity 0.8s ease-out;
        overflow: hidden;
        ${media.desktop} {
          display: flex;
        }
        &.active {
          opacity: 1;
          transition: opacity 0.8s ease-out;
        }

        ${media.mobile} {
          height: 530px;
          width: 375px;
          left: calc(50% - 187.5px);
          background-size: 100%;
          background-position: center bottom;
          background-repeat: no-repeat;
          &.lawyerhome {
            background-image: url(${getStaticImageUrl({ fileName: 'featurecard_lawyer_mobile.png' })});
          }
          &.contents {
            background-image: url(${getStaticImageUrl({ fileName: 'featurecard_contents_mobile.png' })});
          }
          &.zisikin-answer {
            background-image: url(${getStaticImageUrl({ fileName: 'featurecard_zisikin-answer_mobile.png' })});
          }
          &.solution {
            background-image: url(${getStaticImageUrl({ fileName: 'featurecard_solutions_mobile.png' })});
          }
        }
      }
    }
    &--title {
      padding-top: 28px;
      font-size: 18px;
      color: #333333;
      font-family: 'Pretendard-Bold';
      line-height: 22px;
      ${media.desktop} {
        font-size: 24px;
        line-height: 32px;
      }
    }
    &--description {
      margin-top: 6px;
      font-size: 13px;
      line-height: 20px;
      color: #555555;
      ${media.desktop} {
        font-size: 15px;
        line-height: 24px;
        margin-top: 12px;
      }
      &-more {
        color: #757575;
        font-size: 12px;
        display: flex;
        justify-content: center;
        ${media.mobile} {
          padding-right: 5px;
          margin-top: 8px;
          margin-left: 4px;
          font-family: 'Pretendard-Bold';
        }
        ${media.desktop} {
          margin-top: 16px;
          border: 1px solid #e0e0e0;
          width: 137px;
          padding-top: 5px;
          padding-bottom: 6px;
        }
        &-icon {
          ${media.desktop} {
            margin-top: 1px;
            margin-left: 5px;
            transform: rotate(-90deg);
          }
          ${media.mobile} {
            margin-top: -2px;
          }
        }
      }
      &-sub {
        font-size: 10px;
        color: #999999;
        position: absolute;
        ${media.mobile} {
          bottom: 19px;
          width: 100%;
          left: 0px;
        }
        ${media.desktop} {
          bottom: 44px;
          right: 194px;
          font-size: 11px;
        }
      }
      &.solution {
        word-break: keep-all;
        ${media.mobile} {
          padding: 0 20px;
        }
        ${media.desktop} {
          width: 333px;
        }
      }
    }
    &--image-wrap {
      &.lawyerhome {
        background-repeat: no-repeat;
        ${media.desktop} {
          margin: 14px auto 0;
          margin-left: 18px;
          background-size: 82%;
          height: 600px;
          margin-top: 71px;
          width: 392px;
          background-position: calc(50% + 9px) 0;
          background-image: url(${getStaticImageUrl({ fileName: 'featurecard_lawyer_desktop.png' })});
        }
      }
      &.searchengine {
        background-repeat: no-repeat;
        background-image: url(${getStaticImageUrl({ fileName: 'featurecard_contents-searchengine.png' })});
        ${media.mobile} {
          margin: 9px auto 0;
          height: 26px;
          background-size: 47%;
          background-position: calc(50% + 1px) 0;
        }
        ${media.desktop} {
          margin-top: 23px;
          height: 27px;
          width: 392px;
          margin-top: 23px;
          background-size: 46%;
        }
      }
      &.contents {
        background-repeat: no-repeat;
        ${media.desktop} {
          margin-top: 71px;
          margin-left: 44px;
          width: 413px;
          height: 608px;
          background-size: 100%;
          background-image: url(${getStaticImageUrl({ fileName: 'featurecard_contents_desktop.png' })});
        }
      }
      &.zisikin-answer {
        background-repeat: no-repeat;
        ${media.desktop} {
          background-image: url(${getStaticImageUrl({ fileName: 'featurecard_zisikin-answer_desktop.png' })});
          background-size: 64%;
          width: 500px;
          height: 520px;
          margin-left: 139px;
          margin-top: 71px;
        }
      }
      &.solution {
        background-repeat: no-repeat;
        position: relative;
        ${media.desktop} {
          margin-top: 70px;
          margin-left: 58px;
          width: 500px;
          height: 528px;
          background-size: 93%;
          background-image: url(${getStaticImageUrl({ fileName: 'featurecard_solutions_desktop.png' })});
        }
        ${media.mobile} {
          &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px;
            border-color: #ffffff transparent transparent transparent;
            position: absolute;
            top: 104px;
            left: calc(50% + 20px);
          }
        }
      }
      .solution-ment {
        width: 142px;
        background-color: #ffffff;
        font-size: 12px;
        font-family: 'Pretendard-Bold';
        color: #ff5500;
        padding: 7px 0;
        position: absolute;
        left: calc(50% + -4px);
        top: 77px;
        ${media.desktop} {
          top: 84px;
          display: none;
        }
      }
    }
  }
`;

export default MainFeaturesStyle;
