import styled from '@emotion/styled';
import { getStaticImageUrl } from '@utils/createImageUrl';
import media from '@shared/mediaQuery';

const MainInsightStyle = styled('div')`
  .maininsightcomp {
    &--title {
      display: flex;
      &-more {
        transform: rotate(-90deg);
        margin-left: 13px;
        ${media.desktop} {
          margin-top: 15px;
          margin-left: 9px;
        }
      }
    }
    &--description {
      font-family: 'Nanum-Myeongjo-Bold';
      color: #555555;
      font-size: 13px;
      line-height: 19.5px;
      margin-top: 1px;
      letter-spacing: -0.1px;
      ${media.desktop} {
        font-size: 15px;
        margin-left: 9px;
        margin-top: 20px;
      }
    }
    &--adsbanner {
      width: 100%;
      height: 100vw * 0.223;
      background-image: url(${getStaticImageUrl({ fileName: 'ads-banner.jpg' })});
      background-size: cover;
    }
    &--contents {
      ${media.mobile} {
        margin-top: 17px;
        border-top: 1px solid #e0e0e0;
      }
      ${media.desktop} {
        margin-top: 8px;
        display: flex;
      }
    }
    &--content {
      padding: 12px 0;
      align-self: flex-start;
      ${media.mobile} {
        border-bottom: 1px solid #e0e0e0;
        display: flex;
        justify-content: space-between;
      }
      ${media.desktop} {
        margin-right: 16px;
        display: flex;
        flex-direction: column-reverse;
        width: 304px;
      }
      &-summary {
        flex: none;
        ${media.mobile} {
          width: calc(100% - 132px);
        }
      }

      &-title {
        font-size: 15px;
        font-family: 'Pretendard-Bold';
        color: #333333;
        max-width: 204px;
        word-break: keep-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 20px;
        ${media.mobile} {
          height: 40px;
        }
        ${media.desktop} {
          font-size: 18px;
          line-height: 22px;
          color: #3a3a3a;
          max-width: 294px;
          height: 44px;
          margin-top: 12px;
          margin-left: 8px;
        }
      }
      &-tags {
        margin-top: 2px;
        display: flex;
        flex-wrap: wrap;
        ${media.desktop} {
          margin-top: 3px;
          margin-left: 8px;
        }
      }
      &-tag {
        font-size: 11px;
        color: #999999;
        margin-right: 12px;
        ${media.desktop} {
          font-size: 12px;
        }
      }
      &-image {
        background-size: cover;
        background-repeat: no-repeat;
        width: 120px;
        height: 72px;
        ${media.desktop} {
          width: 304px;
          height: 181px;
        }
      }
    }
  }
`;

export default MainInsightStyle;
