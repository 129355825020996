import Main from './main.style';
import MainTopbanner from '@component/MainTopBanner';
import MainWhatWedo from '@component/MainWhatWedo';
// import MainNumbers from '@component/MainNumbers';
import MainFeatures from '@component/MainFeatures';
import MainQandA from '@component/MainQandA';
import MainInsight from '@component/MainInsight';
import MainReview from '@component/MainReview';
import MainAds from '@component/MainAds';
import { LAWTALK_LABEL, LAWTALK_SIGNUP_URL } from '@shared/values';
import QuestionAction from '@component/QuestionAction';
import HttpCommon from '@utils/http-common';
import InsightService from '@service/insightService';
import LawtalkService from '@service/lawtalkService';
import PickType from '../types/pick';
import HeadMetaTag from '@component/HeadMetaTags';
import { MainLayout } from '@component/LayoutContainer';
import { useRouter } from 'next/router';
import { getUtmsFromQuery } from '../utils/utmUtil';
import { useScroll } from '../utils/useScroll';
import { PlusIcon } from '../public/icon/PlusIcon';
import { sendAmplitudeEvent } from '../utils/amplitude';

type mainProps = {
  isMobile: boolean;
  picks: PickType[];
  numbers: {
    lawyerNum: number;
    visitorNum: number;
    advice: number;
    revenue: number;
  };
};

const IndexPage = ({ isMobile, picks = [] }: mainProps) => {
  const { y } = useScroll();
  const numbersHeight = 256;
  const featureStartScroll = isMobile ? (1350 - numbersHeight) : (1550 - numbersHeight);
  const featureFinalScroll = isMobile ? (3335 - numbersHeight) : (3380 - numbersHeight);
  const router = useRouter();
  const goSignup = () => {
    const pathname = router.pathname || '';
    const utms = getUtmsFromQuery(router.query);
    const signupUrl = LAWTALK_SIGNUP_URL({ pagePath: pathname, utms: utms });
    sendAmplitudeEvent('sqr_click_sign-up', { label: 'bottom' });
    window.open(signupUrl);
  };
  return (
    <MainLayout>
      <Main>
        <HeadMetaTag />
        <h1 className="page-title mobile">{LAWTALK_LABEL} 소개</h1>
        <div className="top__banner">
          <MainTopbanner />
        </div>
        <div className="main--body">
          <div className="maincomp--wrap lawtalkvalue">
            <MainWhatWedo />
          </div>
          {/* <div className="maincomp--wrap">
            <MainNumbers numbers={numbers} />
          </div> */}

          <div className="maincomp--wrap features">
            <div
              className={`scrollcards ${featureFinalScroll >= y && y > featureStartScroll && 'active'} ${
                featureFinalScroll < y && 'bottom'
              }`}
            >
              <MainFeatures currentScroll={y} startScroll={featureStartScroll} />
            </div>
          </div>
          <div className="maincomp--wrap">
            <MainQandA isMobile={isMobile} />
          </div>
          <div className="maincomp--wrap review">
            <MainReview />
          </div>
          <div className="maincomp--wrap insight">
            <MainInsight contents={picks} />
          </div>
          <div className="maincomp--wrap ads">
            <MainAds />
          </div>
          <div className="maincomp--meet">
            <p className="maincomp--meet-desc">더 많은 의뢰인을 만나는 방법,</p>
            <p className="maincomp--meet-desc">
              지금 바로 <span className="maincomp--meet-desc-bold">로톡 변호사</span>로 등록하세요
            </p>
            <button className="maincomp--meet-button" onClick={goSignup}>
              <PlusIcon />
              <p className="maincomp--meet-button-text">회원가입</p>
            </button>
          </div>
        </div>
        <QuestionAction />
      </Main>
    </MainLayout>
  );
};

IndexPage.getInitialProps = async () => {
  if (!HttpCommon.isAvailableUrl()) {
    return;
  }

  let picks;
  let numbersResult;
  try {
    numbersResult = await LawtalkService.getLawtalkNumber();

    const res = await InsightService.getPicks();
    picks = res?.data?.map((pick: any) => {
      pick.tags = pick.tags ? pick.tags.split(', ') : [];
      pick.plainContents = String(pick.contents).replace(/<[^>]+>|&[\w]+;/gm, '');
      return pick;
    });

    return { picks, numbers: numbersResult.data };
  } catch (error) {
    console.error('indexpage getInitialProps error :>> ', error);
    return { picks, numbers: { visitorNum: 0, advice: 0 } };
  }
};

export default IndexPage;
