import styled from '@emotion/styled';
import media from '@shared/mediaQuery';

const MainQandAStyle = styled('div')`
  .mainqanda {
    &--contents {
      ${media.mobile} {
        margin-top: 6px;
      }
      ${media.desktop} {
        margin-top: 16px;
        width: 945px;
      }
    }

    &--content {
      overflow: hidden;
      transition: max-height 0.6s ease-out;
      ${media.desktop} {
        max-height: 72px;
      }
      ${media.mobile} {
        max-height: 50px;
      }
      &.active {
        max-height: 300px;
        transition: max-height 0.4s ease-out;

        .mainqanda--arrow-icon {
          transform: rotate(180deg);
          margin-top: 2px;
          ${media.mobile} {
            margin-right: 5px;
          }
          ${media.desktop} {
            margin-right: 20px;
          }
        }
      }
    }

    @keyframes opacityInvisible {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes opacityVisible {
      0% {
        opacity: 0;
      }
      1% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
    &--description {
      font-size: 13px;
      color: #555555;
      line-height: 20px;
      ${media.desktop} {
        font-size: 15px;
        line-height: 24px;
      }
      &-bottombar {
        height: 2px;
        width: 100%;
        background-color: #e0e0e0;
        animation: opacityInvisible 0.7s;
        &.false {
          opacity: 0;
        }
        &.active {
          animation: opacityVisible 0.7s;
        }
      }
      &-bold {
        font-family: 'Pretendard-Bold';
      }
      &.commission {
        ${media.desktop} {
          width: 465px;
        }
      }
    }
    &--title {
      font-size: 15px;
      color: #333333;
      ${media.desktop} {
        font-size: 18px;
      }
      &-wrap {
        border-bottom: solid 2px #e0e0e0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        width: 100%;
        ${media.desktop} {
          height: 72px;
        }
      }
      &-bold {
        font-family: 'Pretendard-Bold';
      }
    }
    &--arrow-icon {
      color: #757575;
      font-size: 15px;
      margin-right: 6px;
      margin-top: -1px;
      ${media.desktop} {
        margin-right: 20px;
      }
    }
  }
`;

export default MainQandAStyle;
