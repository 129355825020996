import { FunctionComponent } from 'react';
import Link from 'next/link';
import MainAdsWrapper from './mainAds.style';
import ArrowIcon from '../../public/icon/Arrow';
import { LAWTALK_ADS, LAWTALK_ADS_LABEL } from '@shared/values';

const MainAds: FunctionComponent = () => {
  return (
    <MainAdsWrapper>
      <div className="mainadscomp__header">
        <Link href="/product/ads">
          <a className="mainadscomp--title">
            <h3 className="maincomp--title hashtag">#{LAWTALK_ADS_LABEL}</h3>
            <p className="mainadscomp--description desktop">효율적인 변호사 광고, 로톡AD.</p>
            <div className="mainadscomp--title-more">
              <ArrowIcon width="15px" height="15px" />
            </div>
          </a>
        </Link>
        <p className="mainadscomp--description mobile">효율적인 변호사 광고, 로톡AD.</p>
      </div>
      <div className="mainadscomp--banner" />
      <div className="mainadscomp--contents">
        {LAWTALK_ADS.map((ads, index) => (
          <div className="mainadscomp--content" key={index}>
            <div className="mainadscomp--content-header">
              <p className="mainadscomp--content-title">{ads.title}</p>
            </div>
            <p className="mainadscomp--content-description">{ads.description}</p>
          </div>
        ))}
      </div>
    </MainAdsWrapper>
  );
};

export default MainAds;
