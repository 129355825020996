import { FunctionComponent } from 'react';
import Link from 'next/link';
import MainFeaturesWrapper from './mainfeatures.style';
import ArrowIcon from '../../public/icon/Arrow';
import ArrowBoldIcon from '../../public/icon/ArrowBold';

type mainFeaturesType = {
  currentScroll: number;
  startScroll: number;
};
const MainFeatures: FunctionComponent<mainFeaturesType> = ({ startScroll, currentScroll }) => {
  const featureheight = 500;
  const calcCardActive = (index: number) => {
    let startCardScroll = startScroll + (index - 1) * featureheight;
    if (index === 1) {
      return startCardScroll + featureheight > currentScroll;
    }
    if (index === 4) {
      return currentScroll > startCardScroll;
    }
    const isActive = startCardScroll + featureheight > currentScroll && currentScroll > startCardScroll;

    return isActive;
  };

  return (
    <MainFeaturesWrapper>
      <p className="maincomp--subtitle">Features</p>
      <h3 className="maincomp--title">로톡을 사용해야 하는 이유</h3>
      <div className="mainfeatures--contents">
        <div className="mainfeatures--contents-dots">
          <div className={`mainfeatures--contents-dot ${calcCardActive(1) && 'active'}`} />
          <div className={`mainfeatures--contents-dot ${calcCardActive(2) && 'active'}`} />
          <div className={`mainfeatures--contents-dot ${calcCardActive(3) && 'active'}`} />
          <div className={`mainfeatures--contents-dot ${calcCardActive(4) && 'active'}`} />
        </div>
        <div className={`mainfeatures--content`}>
          <div className={`mainfeatures--content-body lawyerhome ${calcCardActive(1) && 'active'}`}>
            <div className="mainfeatures--text-wrap">
              <p className="mainfeatures--title">
                나를 브랜딩할 수 있는 <br />
                변호사 홈페이지
              </p>
              <p className="mainfeatures--description">
                가입 후 무료로 제공되는 변호사 홈페이지를 <br />
                퍼스널 브랜딩에 활용해 보세요. <br />
                변호사님의 분야, 경험, 자격, 상담사례, 후기 등을 <br />
                매력적이고 전문성있게 보여드립니다.
              </p>
            </div>
            <div className="mainfeatures--image-wrap lawyerhome" />
          </div>
          <div className={`mainfeatures--content-body contents ${calcCardActive(2) && 'active'}`}>
            <div className="mainfeatures--text-wrap">
              <p className="mainfeatures--title">
                작성하기만 하면
                <br />
                자동으로 홍보되는 법률콘텐츠
              </p>
              <p className="mainfeatures--description">
                블로그처럼 쉽게 법률콘텐츠를 작성해 보세요.
                <br />
                작성한 콘텐츠는 구글과 네이버 등 핵심 검색포털에
                <br />
                자동으로 홍보됩니다.
              </p>
              <div className="mainfeatures--image-wrap searchengine desktop" />
            </div>
            <div className="mainfeatures--image-wrap contents" />
          </div>
          <div className={`mainfeatures--content-body zisikin-answer ${calcCardActive(3) && 'active'}`}>
            <div className="mainfeatures--text-wrap">
              <p className="mainfeatures--title">
                네이버 지식iN에 <br />
                노출되는 나의 상담답변
              </p>
              <p className="mainfeatures--description">
                로톡에서 작성한 상담 답변은
                <br />
                네이버 지식iN에 노출되며,
                <br />
                바로 로톡 변호사 홈페이지와 연결됩니다.
              </p>
            </div>
            <div className="mainfeatures--image-wrap zisikin-answer" />
          </div>
          <div className={`mainfeatures--content-body solution ${calcCardActive(4) && 'active'}`}>
            <div className="mainfeatures--text-wrap">
              <p className="mainfeatures--title">
                의뢰인과 유료상담도
                <br />
                간편하게
              </p>
              <p className="mainfeatures--description solution">
                원하는 상담료 및 시간을 설정하면, 의뢰인이 선결제로 상담을 예약합니다. 상담예약 알림부터 정산까지 로톡이
                도와드리기 때문에 변호사님 본연의 업무에 더욱 집중할 수 있습니다.
              </p>
              <Link href="/product/solution">
                <a className="mainfeatures--description-more">
                  로톡 솔루션 알아보기
                  <div className="mainfeatures--description-more-icon desktop">
                    <ArrowIcon />
                  </div>
                  <div className="mainfeatures--description-more-icon mobile">
                    <ArrowBoldIcon />
                  </div>
                </a>
              </Link>
            </div>
            <div className="mainfeatures--image-wrap solution">
              <div className="solution-ment desktop">상담이 예약되었습니다.</div>
            </div>
            <span className="mainfeatures--description-sub">
              ※특정 변호사의 성과 지표는 실제와 다른 가공의 수치입니다.
            </span>
          </div>
        </div>
      </div>
    </MainFeaturesWrapper>
  );
};

export default MainFeatures;
