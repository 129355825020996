import http from '../utils/http-common';

const getPicks = () => {
  return http.axiosCreate.get(`/pick`);
};

const getList = (count: number, page: string) => {
  return http.axiosCreate.get(`/contents?cnt=${count}&page=${page}`);
};

const getDetail = (insightId: string) => {
  return http.axiosCreate.get(`/contents/${insightId}`);
};

export default {
  getPicks,
  getList,
  getDetail,
};
