import styled from '@emotion/styled';
import media from '@shared/mediaQuery';

const Main = styled('div')`
  ${media.desktop} {
    padding-bottom: 100px;
  }
  ${media.mobile} {
    padding-bottom: 52px;
  }
  .scrollcards {
    &.active {
      position: fixed;
      top: 30px;
      background-color: #ffffff;
      ${media.mobile} {
        width: calc(100% - 40px);
      }
    }
    &.bottom {
      position: absolute;
      bottom: 0;
      width: calc(100% - 40px);
    }
  }
  .maincomp {
    &--wrap {
      ${media.mobile} {
        padding: 20px 20px 0;
      }
      ${media.desktop} {
        width: 944px;
        margin: 0 auto;
      }
      &:not(:first-of-type) {
        margin-top: 60px;
        ${media.desktop} {
          margin-top: 148px;
        }
      }
      &.lawtalkvalue {
        ${media.desktop} {
          margin-top: 84px;
        }
        ${media.mobile} {
          margin-top: 0px;
        }
      }
      &.insight {
        margin-top: 38px;
        ${media.desktop} {
          margin-top: 119px;
        }
      }
      &.ads {
        margin-top: 55px;
        ${media.desktop} {
          margin-top: 142px;
        }
      }
      &.features {
        height: 2520px;
        position: relative;
      }
      &.review {
        ${media.desktop} {
          margin-top: 151px;
          width: 954px;
        }
      }
    }
    &--subtitle {
      font-family: 'Gilroy-ExtraBold';
      font-size: 16px;
      color: #ff5500;
      ${media.desktop} {
        font-size: 18px;
      }
    }
    &--title {
      font-family: 'Pretendard-Bold';
      font-size: 24px;
      color: #333333;
      margin-top: 2px;
      ${media.desktop} {
        font-size: 32px;
        margin-top: 4px;
      }
      &.hashtag {
        ${media.desktop} {
          font-size: 28px;
        }
      }
    }
    &--meet {
      ${media.mobile} {
        margin-top: 80px;
      }
      ${media.desktop} {
        width: 944px;
        margin: 116px auto 0;
      }
      &-desc {
        text-align: center;
        color: #333333;
        font-size: 18px;
        ${media.desktop} {
          font-size: 28px;
        }
        &:last-of-type {
          margin-top: -2px;
          &:after {
            content: '.';
            color: #ff5500;
          }
        }
        &-bold {
          font-family: 'Pretendard-Bold';
          font-size: 18px;
          color: #333333;
          ${media.desktop} {
            font-size: 28px;
          }
        }
      }
      &-button {
        display: block;
        width: 96px;
        height: 96px;
        border-radius: 100%;
        background-color: #ff5500;
        margin: 16px auto 0;
        ${media.desktop} {
          margin: 26px auto 0;
        }
        p {
          color: #ffffff;
        }
        svg {
          margin-top: -7px;
        }
        &-text {
          font-family: 'Pretendard-Bold';
          font-size: 15px;
        }
      }
    }
  }
`;

export default Main;
