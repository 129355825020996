import { FunctionComponent } from 'react';
import Link from 'next/link';
import MainInsightWrapper from './mainInsight.style';
import ArrowIcon from '../../public/icon/Arrow';
import { LAWTALK_INSIGHT } from '@shared/values';
import PickType from '../../types/pick';

type MainInsight = {
  contents: PickType[];
};

const MainInsight: FunctionComponent<MainInsight> = ({ contents = [] }) => {
  return (
    <MainInsightWrapper>
      <Link href="/insight">
        <a className="maininsightcomp--title">
          <h3 className="maincomp--title hashtag">#{LAWTALK_INSIGHT}</h3>
          <p className="maininsightcomp--description desktop">
            변호사 마케팅 인사이트부터 로톡 활용방법까지, 로톡이 전하는 노하우를 놓치지 마세요.
          </p>
          <div className="maininsightcomp--title-more">
            <ArrowIcon width="15px" height="15px" />
          </div>
        </a>
      </Link>
      <p className="maininsightcomp--description mobile">
        변호사 마케팅 인사이트부터 로톡 활용방법까지, 로톡이 전하는 노하우를 놓치지 마세요.
      </p>
      <div className="maininsightcomp--contents">
        {contents.map((cont: any, contIndex) => (
          <Link href={`/insight/${cont.id}`} key={contIndex}>
            <a className="maininsightcomp--content">
              <div className="maininsightcomp--content-summary">
                <p className="maininsightcomp--content-title">{cont.title}</p>
                <div className="maininsightcomp--content-tags">
                  {cont.tags.map((tag: string, index: number) => (
                    <span className="maininsightcomp--content-tag" key={index}>
                      #{tag}
                    </span>
                  ))}
                </div>
              </div>
              <div
                className="maininsightcomp--content-image"
                style={{ backgroundImage: `url(${cont.titleImageUrl})` }}
              />
            </a>
          </Link>
        ))}
      </div>
    </MainInsightWrapper>
  );
};

export default MainInsight;
