import styled from '@emotion/styled';
import media from '@shared/mediaQuery';

const MainReviewCardStyle = styled('div')`
  box-shadow: 0 0 10px 1px #33333326;
  flex: none;
  margin-right: 16px;
  position: relative;
  ${media.mobile} {
    padding-top: 35px;
    width: 260px;
  }
  ${media.desktop} {
    padding-top: 58px;
    padding-bottom: 100px;
    width: 304px;
  }
  &:first-of-type {
    ${media.mobile} {
      margin-left: 20px;
    }
    ${media.desktop} {
      margin-left: 8px;
    }
  }
  .reviewcard {
    &--header {
      position: relative;
      &-back {
        font-family: 'Pretendard-Bold';
        font-size: 60px;
        color: #e0e0e0;
        position: absolute;
        top: -30px;
        left: 30px;
        z-index: -1;
        ${media.desktop} {
          top: -40px;
          font-size: 70px;
        }
      }
      &-title {
        font-family: 'Pretendard-Bold';
        text-align: center;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
        margin: 0 auto;
        word-break: keep-all;
        ${media.mobile} {
          max-width: 190px;
        }
        ${media.desktop} {
          max-width: 206px;
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
    &--body {
      ${media.mobile} {
        margin-top: 12px;
      }
      ${media.desktop} {
        margin-top: 16px;
      }
      &-text {
        font-size: 13px;
        line-height: 20px;
        color: #555555;
        text-align: center;
        margin: 0 auto;
        word-break: keep-all;
        ${media.mobile} {
          max-width: 220px;
        }
        ${media.desktop} {
          max-width: 236px;
          font-size: 14px;
          line-height: 22px;
        }
        span {
          font-family: 'Pretendard-Bold';
        }
      }
    }
    &--footer {
      position: absolute;
      width: 100%;
      ${media.mobile} {
        bottom: 24px;
      }
      ${media.desktop} {
        bottom: 44px;
      }
      &-content {
        display: flex;
        justify-content: center;
        margin-left: -5px;
      }
      &-lawyerimg {
        border-radius: 100%;
        width: 48px;
        height: 48px;
      }
      &-description {
        margin-top: 5px;
      }
      &-name {
        font-family: 'Nanum-Myeongjo-Bold';
        font-size: 13px;
        color: #333333;
        margin-left: 8px;
        ${media.desktop} {
          font-size: 14px;
          margin-left: 7px;
        }
      }
      &-link {
        font-size: 12px;
        color: #555555;
        display: flex;
        margin-left: 9px;
        margin-top: 8px;
        ${media.desktop} {
          margin-left: 8px;
        }
      }
      &-linkarrow {
        transform: rotate(-90deg);
        margin-left: 5px;
      }
    }
  }
`;

export default MainReviewCardStyle;
