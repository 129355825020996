import { cx } from '@emotion/css';
import { FunctionComponent } from 'react';
import Button from '@element/Button';
import MainTopBannerWrapper from './maintopbanner.styles';
import { LAWTALK_SIGNUP_URL } from '@shared/values';
import { useRouter } from 'next/router';
import { getUtmsFromQuery } from '../../utils/utmUtil';
import { PlusIcon } from '../../public/icon/PlusIcon';
import { sendAmplitudeEvent } from '../../utils/amplitude';
import moment from 'moment';

const PROMOTION_END_DATE = '2023-12-31';

const Navigation: FunctionComponent = () => {
  const router = useRouter();
  const goSignup = () => {
    const pathname = router.pathname || '';
    const utms = getUtmsFromQuery(router.query);
    const signupUrl = LAWTALK_SIGNUP_URL({ pagePath: pathname, utms: utms });
    sendAmplitudeEvent('sqr_click_sign-up', { label: 'top' });
    window.open(signupUrl);
  };

  const diffDaysFromToday = moment(PROMOTION_END_DATE).startOf('day').diff(moment().startOf('day'), 'days');
  const goBigcasePromotion = () => {
    const bigcaseNoticeLink = 'https://bigcase.ai/notices/29';
    window.open(bigcaseNoticeLink);
  };

  return (
    <MainTopBannerWrapper>
      <div className="main-top-banner__background" />
      <div className={cx('main-top-banner__contents', { promotion: 0 <= diffDaysFromToday })}>
        <div className="main-top-banner__title">
          <p className="main-top-banner__title-text">
            대한민국 <br className="desktop" />
            변호사라면,
            <span className="main-top-banner__title-text--highlight">
              <br className="mobile" /> 로톡
            </span>
          </p>
        </div>
        {0 <= diffDaysFromToday ? (
          <div>
            <div className="main-top-banner__description mobile">신규 가입 시 판례검색 서비스</div>
            <span className="main-top-banner__description mobile">빅케이스Plus 구독권 제공 (~12/31)</span>
            <span className="main-top-banner__description desktop">
              신규 가입 시 판례검색 서비스 빅케이스Plus 구독권 제공 (~12/31)
            </span>
            <span className="main-top-banner__link" onClick={goBigcasePromotion}>
              자세히
            </span>
            <div className="main-top-banner__button">
              <Button onClick={goSignup}>
                무료로 변호사 회원가입하기 <PlusIcon />
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <p className="main-top-banner__description mobile">
              요즘 의뢰인의 70% 이상은 <br />
              인터넷을 통해 변호사를 탐색합니다. <br />
              국내 1위 법률서비스 플랫폼 로톡, <br />
              변호사라면 이제 선택이 아닌 필수입니다.
            </p>
            <p className="main-top-banner__description desktop">
              요즘 의뢰인의 70% 이상은 인터넷을 통해 변호사를 탐색합니다. <br />
              국내 1위 법률서비스 플랫폼 로톡, 변호사라면 이제 선택이 아닌 필수입니다.
            </p>
            <div className="main-top-banner__button">
              <Button onClick={goSignup}>
                무료로 변호사 회원가입하기 <PlusIcon />
              </Button>
            </div>
          </div>
        )}
      </div>
    </MainTopBannerWrapper>
  );
};

export default Navigation;
