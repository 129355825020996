import styled from '@emotion/styled';
import media from '@shared/mediaQuery';

const MainWhatWedoStyle = styled('div')`
  .mainwhatwedo {
    &--contents {
      margin-top: 32px;
      ${media.desktop} {
        display: flex;
      }
    }
    &--content {
      position: relative;
      ${media.mobile} {
        text-align: center;
      }
      ${media.desktop} {
        width: 303px;
      }
      &:not(:first-of-type) {
        ${media.mobile} {
          margin-top: 32px;
        }
        ${media.desktop} {
          margin-left: 16px;
        }
      }
      &:last-of-type {
        ${media.desktop} {
          width: 310px;
        }
      }
    }
    &--number {
      font-family: 'Gilroy-ExtraBold';
      font-size: 40px;
      color: #cfcfcf;
      position: absolute;
      z-index: -1;
      opacity: 0.2;
      letter-spacing: 2.69px;
      ${media.mobile} {
        left: calc(50% - 106px);
        top: -24px;
      }
      ${media.desktop} {
        left: calc(50% - 150px);
        top: -5px;
        font-size: 70px;
      }
    }
    &--title {
      font-family: 'Pretendard-Regular';
      font-size: 20px;
      line-height: 26px;
      color: #333333;
      margin-bottom: 4px;
      ${media.desktop} {
        font-size: 24px;
        text-align: left;
        margin-top: 38px;
        line-height: 32px;
        margin-bottom: 16px;
      }
    }
    &--description {
      font-family: 'Pretendard-Regular';
      font-size: 14px;
      color: #555555;
      line-height: 22px;
      ${media.desktop} {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;

export default MainWhatWedoStyle;
