import { FunctionComponent } from 'react';
import ReviewCardWrapper from './reviewcard.style';
import ArrowIcon from '../../public/icon/Arrow';

type reviewcardType = {
  title: string;
  description: string;
  lawyerImage: string;
  lawyerName: string;
  lawyerProfileLink: string;
};

const ReviewCard: FunctionComponent<reviewcardType> = ({
  title,
  description,
  lawyerImage,
  lawyerName,
  lawyerProfileLink,
}) => {
  return (
    <ReviewCardWrapper>
      <div className="reviewcard--header">
        <p className="reviewcard--header-back">“</p>
        <p className="reviewcard--header-title">{title}</p>
      </div>
      <div className="reviewcard--body">
        <p className="reviewcard--body-text" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <div className="reviewcard--footer">
        <div className="reviewcard--footer-content">
          <img src={lawyerImage} className="reviewcard--footer-lawyerimg" />
          <div className="reviewcard--footer-description">
            <p className="reviewcard--footer-name">{lawyerName}</p>
            <a href={lawyerProfileLink} target="_blank" className="reviewcard--footer-link">
              변호사 홈페이지
              <div className="reviewcard--footer-linkarrow">
                <ArrowIcon />
              </div>
            </a>
          </div>
        </div>
      </div>
    </ReviewCardWrapper>
  );
};

export default ReviewCard;
