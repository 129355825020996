const ArrowBold = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g fill="#757575">
          <g>
            <g>
              <path
                d="M12.743 15.95h-6.5v-1.5h6.5v-6.5h1.5v8h-1.5z"
                transform="translate(-228 -1801) translate(123 1801) translate(105) rotate(-45 10.243 11.95)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowBold;
