import { FunctionComponent } from 'react';
import MainWhatWedoWrapper from './mainwhatwedo.style';

const MainWhatWedo: FunctionComponent = () => {
  return (
    <MainWhatWedoWrapper>
      <p className="maincomp--subtitle">What we do</p>
      <h3 className="maincomp--title">로톡의 가치</h3>
      <div className="mainwhatwedo--contents">
        <div className="mainwhatwedo--content">
          <p className="mainwhatwedo--number">01</p>
          <p className="mainwhatwedo--title">
            매년 더 많은 법률소비자가 <br /> 로톡을 찾고 있습니다.
          </p>
          <span className="mainwhatwedo--description">
            로톡의 풍부한 법률 콘텐츠와 <br /> 편리한 상담예약시스템은 <br /> 법률소비자가 로톡을 찾는 이유입니다.
          </span>
        </div>
        <div className="mainwhatwedo--content">
          <p className="mainwhatwedo--number">02</p>
          <p className="mainwhatwedo--title">
            업무 효율을 <br /> 향상시켜 드립니다.
          </p>
          <span className="mainwhatwedo--description">
            변호사님의 업무에 도움이 되는 다양한 <br />
            솔루션을 만듭니다.
          </span>
        </div>
        <div className="mainwhatwedo--content">
          <p className="mainwhatwedo--number">03</p>
          <p className="mainwhatwedo--title">
            변호사님의 경쟁력을 <br /> 높여드립니다.
          </p>
          <span className="mainwhatwedo--description">
            변호사님이 원하는 분야/지역에 맞는 의뢰인에게만
            <br className="mobile" /> 효과적으로 광고할 수 있도록 돕습니다.
          </span>
        </div>
      </div>
    </MainWhatWedoWrapper>
  );
};

export default MainWhatWedo;
