import { FunctionComponent, useState, useRef, useEffect, DetailedHTMLProps, HTMLAttributes } from 'react';
import MainReviewWrapper from './mainReview.style';
import MainReviewCard from '@component/ReviewCard';
import ArrowIcon from '../../public/icon/Arrow';
import { LAWTALK_SIGNUP_URL } from '@shared/values';
import { useRouter } from 'next/router';
import { getUtmsFromQuery } from '../../utils/utmUtil';

const MainReview: FunctionComponent = () => {
  const reviewCardData = [
    {
      title: '로톡은 체계적이며 신뢰도 높은 법률 플랫폼',
      description:
        '원하는 시간에 상담 일정을 설정할 수 있어 <span>스케쥴을 체계적으로 관리</span>할 수 있습니다. 전문분야 위주의 법률서비스를 제공하는 로톡의 시스템은 <span>변호사와 의뢰인 모두에게 높은 만족도</span>를 줍니다.',
      lawyerImage: 'https://www.lawtalk.co.kr/uploads/thumb/5d492f7564661029afe537a5-thumb.jpg',
      lawyerName: '김한솔 변호사님',
      lawyerProfileLink: 'https://www.lawtalk.co.kr/directory/profile/9045-%EA%B9%80%ED%95%9C%EC%86%94',
    },
    {
      title: '매월 꾸준한 수임이 가능합니다.',
      description:
        '로톡에서 1년 넘게 꾸준히 활동하면서 약 800명의 잠재적 의뢰인 연락처를 확보하였고, 지금도 로톡에서 <span>매월 월 20~30% 이상은 꾸준히 수임</span>하고 있습니다.',
      lawyerImage: 'https://www.lawtalk.co.kr/uploads/thumb/559a4230131592e74cbd9cf7-thumb.jpg',
      lawyerName: '서지원 변호사님',
      lawyerProfileLink: 'https://www.lawtalk.co.kr/directory/profile/0208-%EC%84%9C%EC%A7%80%EC%9B%90',
    },
    {
      title: '로톡에서는 의미있는 의뢰인을 만날 수 있어요.',
      description:
        '로톡에서 2년 이상 활동을 하면서 <span>지금까지 2,000건이 넘는 법률상담을 진행하였습니다.</span> 특히 15분 전화상담은 변호사에게 의미 있는 법률문제를 겪고 있는 의뢰인을 만날 확률이 더욱 높아 좋습니다.',
      lawyerImage: 'https://www.lawtalk.co.kr/uploads/thumb/53101398a52329d5060002b6-thumb.jpg',
      lawyerName: '조석근 변호사님',
      lawyerProfileLink: 'https://www.lawtalk.co.kr/directory/profile/0114-%EC%A1%B0%EC%84%9D%EA%B7%BC',
    },
  ];

  const router = useRouter();
  const POSSIBLE_SLIDES = 1;
  const SLIDE_UNIT = 3;
  const pathname = router.pathname || '';
  const utms = getUtmsFromQuery(router.query);
  const signupUrl = LAWTALK_SIGNUP_URL({ pagePath: pathname, utms: utms });

  const [currentSlide, setCurrentSlide] = useState(0);
  const slideRef = useRef<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>>(null);
  const nextSlide = () => {
    if (currentSlide >= POSSIBLE_SLIDES) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };
  const prevSlide = () => {
    if (currentSlide === 0) {
      setCurrentSlide(POSSIBLE_SLIDES);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };

  useEffect(() => {
    if (slideRef && slideRef?.current && slideRef.current.style) {
      slideRef.current.style.transition = 'all 0.5s ease-in-out';
      slideRef.current.style.transform = `translateX(-${currentSlide * SLIDE_UNIT * 321}px)`;
    }
  }, [currentSlide]);

  return (
    <MainReviewWrapper>
      <p className="maincomp--subtitle">Review</p>
      <h3 className="maincomp--title">변호사님의 로톡 이용 후기</h3>
      <button onClick={prevSlide} className={`mainreview--prevbutton desktop ${currentSlide === 0 ? 'disabled' : ''}`}>
        <ArrowIcon width="25px" height="25px" />
      </button>
      <button
        onClick={nextSlide}
        className={`mainreview--nextbutton desktop ${currentSlide >= POSSIBLE_SLIDES ? 'disabled' : ''}`}
      >
        <ArrowIcon width="25px" height="25px" />
      </button>
      <div className="mainreview--contents">
        {/* 
        // @ts-ignore */}
        <div className="mainreview--carouselwrap" ref={slideRef}>
          {reviewCardData.map((data, idx) => {
            const { title, description, lawyerImage, lawyerName, lawyerProfileLink } = data;
            return (
              <MainReviewCard
                title={title}
                description={description}
                lawyerImage={lawyerImage}
                lawyerName={lawyerName}
                lawyerProfileLink={lawyerProfileLink}
                key={idx}
              />
            );
          })}
          <div className="mainreview-finalcard">
            <p className="mainreview-finalcard-title">변호사님도 놀라운 성과를 경험해 보세요!</p>
            <a className="mainreview-finalcard-link" href={signupUrl} target="_blank">
              변호사 회원가입하기
            </a>
          </div>
          <div className="mainreview-finalcard-vaccum" />
        </div>
      </div>
    </MainReviewWrapper>
  );
};

export default MainReview;
