import styled from '@emotion/styled';
import media from '@shared/mediaQuery';
import { getStaticImageUrl } from '@utils/createImageUrl';

const MainReviewStyle = styled('div')`
  ${media.desktop} {
    position: relative;
  }
  .mainreview {
    &--carouselwrap {
      display: flex;
    }
    &--prevbutton {
      ${media.desktop} {
        position: absolute;
        bottom: 190px;
        left: -39px;
        transform: rotate(90deg);
        &.disabled {
          display: none;
        }
      }
    }
    &--nextbutton {
      ${media.desktop} {
        position: absolute;
        bottom: 190px;
        right: -29px;
        transform: rotate(-90deg);
        &.disabled {
          display: none;
        }
      }
    }
    &--contents {
      margin-top: 18px;
      padding: 20px 0;
      ${media.mobile} {
        margin-left: -20px;
        width: calc(100% + 40px);
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
      }
      ${media.desktop} {
        max-width: 960px;
        overflow-x: hidden;
        margin-left: -8px;
      }
    }
    &-finalcard {
      flex: none;
      box-shadow: 0 0 10px 1px #33333326;
      background-image: url(${getStaticImageUrl({ fileName: 'man_border.png' })});
      ${media.mobile} {
        width: 260px;
        height: 296px;
        background-size: 110%;
        background-position: -24px -43px;
      }
      ${media.desktop} {
        width: 304px;
        height: 364px;
        background-size: 116%;
        background-position: -45px -54px;
      }
      &-title {
        font-family: 'Nanum-Myeongjo-Bold';
        color: #555555;
        line-height: 27px;
        text-align: center;
        word-break: keep-all;
        ${media.mobile} {
          margin: 143px auto 0;
          font-size: 20px;
          max-width: 128px;
        }
        ${media.desktop} {
          margin: 168px auto 0;
          font-size: 24px;
          line-height: 32px;
          max-width: 153px;
        }
      }
      &-link {
        color: #ff5500;
        font-size: 12px;
        font-family: 'Pretendard-Bold';
        border-bottom: 1px solid #ff5500;
        text-decoration: none;
        width: 103px;
        display: block;
        margin: 8px auto 0;
        height: 16px;
        ${media.desktop} {
          margin: 12px auto 0;
        }
      }
      &-vaccum {
        width: 20px;
        flex: none;
      }
    }
  }
`;

export default MainReviewStyle;
