import { FunctionComponent, useState } from 'react';
import MainQandAWrapper from './mainqanda.style';
import ArrowIcon from '../../public/icon/Arrow';

const MainQandA: FunctionComponent<{ isMobile: boolean }> = ({ isMobile }) => {
  const [selectIndex, setSelectIndex] = useState<boolean[]>([false, false, false, false]);
  const clickQuestion = (index: number) => {
    const convertIndexs = selectIndex?.map((isOpen, idx) => (idx === index ? !isOpen : isOpen));
    setSelectIndex(convertIndexs);
  };
  const arrowIconWidth = isMobile ? '12px' : '24px';
  const arrowIconHeight = isMobile ? '7px' : '10px';
  return (
    <MainQandAWrapper>
      <p className="maincomp--subtitle">Q&A</p>
      <h3 className="maincomp--title">자주하는 질문</h3>
      <div className="mainqanda--contents">
        <div className={`mainqanda--content ${selectIndex[0] && 'active'}`}>
          <button className="mainqanda--title-wrap" onClick={() => clickQuestion(0)}>
            <p className="mainqanda--title">
              로톡은 <span className="mainqanda--title-bold">가입비가</span> 있나요?
            </p>
            <div className="mainqanda--arrow-icon">
              <ArrowIcon width={arrowIconWidth} height={arrowIconHeight} />
            </div>
          </button>
          <div className="mainqanda--description-wrap">
            <br />
            <p className="mainqanda--description">
              로톡은 변호사 회원가입부터 변호사 홈페이지 생성, 온라인상담 등 모든 이용 및 활동이&nbsp;
              <span className="mainqanda--description-bold">무료</span>
              입니다. <br />
              활동할수록 변호사님이 <span className="mainqanda--description-bold">더 많이 홍보</span>되니 지금
              시작해보세요.
            </p>
            <br />
            <br />
          </div>
        </div>
        <div className={`mainqanda--description-bottombar ${selectIndex[0] && 'active'}`} />
        <div className={`mainqanda--content ${selectIndex[2] && 'active'}`}>
          <button className={`mainqanda--title-wrap`} onClick={() => clickQuestion(2)}>
            <p className="mainqanda--title">변호사 회원가입 자격이 있나요?</p>
            <div className="mainqanda--arrow-icon">
              <ArrowIcon width={arrowIconWidth} height={arrowIconHeight} />
            </div>
          </button>
          <div className="mainqanda--description-wrap">
            <br />
            <p className="mainqanda--description">
              대한민국 <span className="mainqanda--description-bold">변호사 자격증을 보유한 변호사</span>라면 누구나
              무료로 가입을 할 수 있습니다. <br />
              단, 등록 전이거나 휴업상태인 변호사님이시면 운영팀으로 문의하세요.
            </p>
            <br />
            <br />
          </div>
        </div>
        <div className={`mainqanda--description-bottombar ${selectIndex[2] && 'active'}`} />
        <div className={`mainqanda--content ${selectIndex[1] && 'active'}`}>
          <button className="mainqanda--title-wrap" onClick={() => clickQuestion(1)}>
            <p className="mainqanda--title">로톡에서의 법률상담, 얼마나 의미가 있나요?</p>
            <div className="mainqanda--arrow-icon">
              <ArrowIcon width={arrowIconWidth} height={arrowIconHeight} />
            </div>
          </button>
          <div className="mainqanda--description-wrap">
            <br />
            <p className="mainqanda--description">
              의뢰인은 법률상담 선결제 솔루션을 통하여 15분 전화상담/30분 방문상담을 예약합니다.
              <br />
              해당 솔루션은 변호사님이 원하는 시간과 상담료를 설정 할 수 있으며, 유료로 상담을 예약하는 의뢰인들은
              법률문제가 위중하고 법률상담에 돈을 지불할 의사가 있는 사람들이기 때문에&nbsp;
              <span className="mainqanda--description-bold">수임으로 연결될 가능성이 높습니다.</span>
            </p>
            <br />
            <br />
          </div>
        </div>
        <div className={`mainqanda--description-bottombar ${selectIndex[1] && 'active'}`} />
        <div className={`mainqanda--content ${selectIndex[3] && 'active'}`}>
          <button className="mainqanda--title-wrap" onClick={() => clickQuestion(3)}>
            <p className="mainqanda--title">
              로톡은 <span className="mainqanda--title-bold">중개 수수료</span>를 받나요?
            </p>
            <div className="mainqanda--arrow-icon">
              <ArrowIcon width={arrowIconWidth} height={arrowIconHeight} />
            </div>
          </button>
          <div className="mainqanda--description-wrap">
            <br />
            <p className="mainqanda--description commission">
              로톡은 변호사법 준수를 최우선시하여,&nbsp;
              <span className="mainqanda--description-bold">중개 수수료를 일절 받지 않습니다.</span> 로톡을 통한
              유료상담료와 수임료 전부 변호사님에게 귀속됩니다.
            </p>
            <br />
            <br />
          </div>
        </div>
        <div className={`mainqanda--description-bottombar ${selectIndex[3] && 'active'}`} />
      </div>
    </MainQandAWrapper>
  );
};

export default MainQandA;
