import styled from '@emotion/styled';
import media from '@shared/mediaQuery';
import { getStaticImageUrl } from '@utils/createImageUrl';

const MainTopBannerStyle = styled('div')`
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, #443736, #443736);
  position: relative;
  ${media.mobile} {
    height: 315px;
  }
  ${media.desktop} {
    height: 524px;
  }
  .main-top-banner {
    &__background {
      opacity: 0.5;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right;
      width: 100%;
      height: 100%;
      ${media.desktop} {
        background-image: url(${getStaticImageUrl({ fileName: 'topbanner_desktop.jpg' })});
      }
      ${media.mobile} {
        background-image: url(${getStaticImageUrl({ fileName: 'topbanner_mobile.png' })});
      }
    }
    &__contents {
      position: absolute;
      top: 0;
      ${media.mobile} {
        left: calc(50% - 133.5px);
        top: 37px;
        &.promotion {
          top: 64px;
        }
      }
      ${media.desktop} {
        left: calc(50% - 470px);
        top: 128px;
        &.promotion {
          top: 141px;
        }
      }
    }

    &__title {
      text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
      font-size: 28px;
      ${media.desktop} {
        display: flex;
        font-size: 48px;
        margin-bottom: 16px;
      }
    }
    &__title-text {
      color: #ffffff;
      ${media.mobile} {
        text-align: center;
      }
      &--highlight {
        font-family: 'Pretendard-Bold';
        font-size: 28px;
        ${media.desktop} {
          font-size: 48px;
        }
        &::after {
          content: '.';
          color: #ff5500;
          font-size: 28px;
          ${media.desktop} {
            font-size: 44px;
          }
        }
      }
    }
    &__description {
      font-size: 15px;
      word-break: keep-all;
      line-height: 24px;
      color: #fff;
      text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
      ${media.mobile} {
        width: 267px;
        margin-top: 16px;
        text-align: center;
      }
      ${media.desktop} {
        width: 555px;
        font-size: 18px;
        line-height: 28px;
        margin-top: 8px;
      }
    }
    &__link {
      margin-left: 8px;
      font-size: 15px;
      word-break: keep-all;
      line-height: 24px;
      color: #fff;
      text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
      text-decoration: underline;
      cursor: pointer;
      ${media.desktop} {
        font-size: 18px;
        line-height: 28px;
      }
    }
    &__button {
      ${media.mobile} {
        width: 240px;
        margin: 16px auto 0;
      }
      ${media.desktop} {
        width: 260px;
        margin-top: 28px;
        button {
          font-size: 16px;
          line-height: 20px;
          height: 56px;
          padding-left: 9px;
          ${media.mobile} {
            padding-left: 8px;
          }
        }
      }
      svg {
        padding-left: 10px;
        ${media.mobile} {
          padding-left: 10px;
        }
      }
    }
  }
`;

export default MainTopBannerStyle;
